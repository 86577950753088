import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
export const _frontmatter = {
  "title": "Arbour Assembly - Attaching Strengthening Struts to Main Beams & Posts"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Step 5. Attaching Strengthening Struts to Main Beams & Posts`}</h1>
    <p>{`These are provided not only for the strengthening of the whole structure but they also look good and help bring the whole project to an attractive conclusion. Please see Figs 23 & 24 below for fixing instructions of the strengthening struts.`}</p>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-5-attaching-strengthening-struts-to-main-beams-posts/arbour-fig-22.jpg" alt="A diagram displaying how to fit the diagonal strengthening struts to the post and main beam at a 45 degree angle" />
  <span className="text-metadata">Fig 23</span>
    </div>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-5-attaching-strengthening-struts-to-main-beams-posts/fig-19.jpg" alt="A diagram displaying a side view of the entire pergola, with a strengthening strut attached to the post at either end" />
  <span className="text-metadata">Fig 24</span>
    </div>
    <p>{`If you have specified a either a 9ft x 16ft or a 9ft x 23ft pergola, we have provided another 3 struts (per extra post) for attaching to the rafter, main beam and post for added strength and continuity.`}</p>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-5-attaching-strengthening-struts-to-main-beams-posts/fig-23.jpg" alt="An isometric diagram displaying how to fit the diagonal strengthening struts to the centre posts of 9ft x 16ft and 9ft x 23ft pergolas" />
  <span className="text-metadata">Fig 25</span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      